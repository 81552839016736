/* src/pages/auth/LoginPage.scss */

.login-page {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    height: 100vh;
    background-color: white;
    flex-direction: column;

    h2{
      font-weight: 400;
      font-size: 22px;
      color: #333;
      margin-bottom: 30px ;
    }
    .input-group{
      margin-bottom: 1.5rem;
      text-align: left;

      input {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 5px  !important;
        font-size: 16px;
        outline: none;
        transition: border-color 0.3s;
      }

      label {
        display: block;
        font-weight: 500;
        z-index: 9999;
      }
      
      input:focus {
        border-color: #6c63ff;
      }
      
    }


  .login-button {
    width: 191px;
    padding: 0.75rem;
    background-color: #ECE6F0;
    color: black;
    border: none;
    border-radius: 13px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
  
  .login-button:hover {
    background-color: #ECE6F0;
  }
  
  .login-button:active {
    background-color: #ECE6F0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  }
  
  .login-container {
    background-color: white;
    padding: 2rem;
    
    // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 100%;
  }
  
  .logo {
    max-width: 300px;
    width: 80%;
    margin-bottom: 1.5rem;
    margin-top: 4rem;
  }
  
  
  
 
  
 
  
  .border-label {
    position: absolute;
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0 5px;
    font-size: 10px;
    color: #555;
  }
  

  /* Center the loader */
.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #333;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Error popup styling */
  .error-popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
  