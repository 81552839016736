body {
    margin: 0;
    font-family:  "Roboto", sans-serif;;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html,
body {
    height: 100%; /* Ensures that the body takes up the full height of the viewport */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
    box-sizing: border-box; /* Ensures padding and border are included in width/height */
}
body {
    overflow-x: hidden; /* Prevents horizontal overflow */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "./variable";
// // change the theme
// $theme-colors: (
//     "primary": $primary,
//     "danger": #ff4136,
//     "secondary": $secondary,
// );

// $card-border-radius: 1.5rem;

// :root {
//     --bs-card-border-radius: 1rem; /* Custom radius for cards */
// }
// // $card-height: 100%;
// @import "~bootstrap/scss/bootstrap.scss";
