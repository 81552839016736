.navbar-page {
    height: 100%;
    width: 100%;
    padding: 25px 28px;
}

.navbar-header {
    padding: 18px 30px;
    background-color: #f3edf7;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    text-align: center;
    svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
    }

    .subheading {
        font-size: 14px;
        font-weight: 400;
    }

    .navbar-search {
        display: flex;
        justify-content: end;
        align-items: center;
        input {
            border: none;
            border-radius: 50px;
            height: 40px;
            transition: opacity 0.2s ease-out, transform 0.2s ease-out; /* Ease-in effect */
            opacity: 0;
            visibility: hidden;
            width: 0;
            margin: 0;
            padding: 0;
        }
        input.active {
            opacity: 1;
            visibility: visible;
            margin-right: 8px;
            padding: 0 20px;
            width: 75%;
        }
    }
}

.loading-overlay {
    position: fixed; /* No quotes */
    top: 0; /* No quotes */
    left: 0; /* No quotes */
    width: 100%; /* No quotes */
    height: 100%; /* No quotes */
    background-color: rgba(255, 255, 255, 0.7); /* Fixed the color format */
    backdrop-filter: blur(5px); /* No quotes */
    display: flex; /* No quotes */
    align-items: center; /* No quotes */
    justify-content: center; /* No quotes */
    z-index: 9999; /* No quotes */
}

.list-item {
    background-color: #fef7ff;
    // padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    padding: 15px;
    div {
        display: flex;
        align-items: center;
    }
    img {
        width: 100px;
        max-height: 50px;
        object-fit: fill;
        margin-right: 10px;
    }
}

.card {
    margin-bottom: 16px;

    .card-img-top {
        border-radius: 0;
        max-height: 300px;
        object-fit: cover;
    }

    .body {
        color: #49454f;
        font-size: 0.9rem;
    }

    .title {
        font-size: 1rem;
    }
    .header {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 1rem;

        .logo-img {
            margin-right: 0.5rem;
            width: 100px;
            max-height: 50px;
            object-fit: fill;
        }
    }
    .card-btn {
        display: flex;
        justify-content: end;
        margin-top: 20px;
        button,
        button:hover,
        button:active,
        button.btn:active  {
            color: #6750a4;
            padding: 8px 25px;
            border: 1px solid #6750a4;
            border-radius: 3rem;
            background-color: #FEF7FF;
        }
    }
    .disabled-btn{
        color: #CAC4D0 !important;
        border: 1px solid #CAC4D0 !important;
        cursor: none;
    }
}

.modal-content{
    .modal-header{
        justify-content: space-between;
    }
    button {
        background-color: #e8def8 !important;
        color: #1d192b;
        font-size: 14px;
        padding: 10px 24px !important;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10rem;
        text-transform: uppercase;
        font-weight: 500;
    }
}


.tenant-page {
    height: 100%;
    width: 100%;
    padding-bottom: 32px;



    .image-container {
        position: relative;

        .overlay {
            position: absolute;
            height: 60px;
            width: 60px;
            top: 50%;
            left: 50%;
            border-radius: 20px;
            transform: translate(-50%, -50%);
            background-color: #ffd8e4;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 40%;
                height: 40%;
            }
        }
    }

    font-family: "Roboto", sans-serif;
    font-style: normal;
    img {
        width: 100%;
        height: 25vh;
        object-fit: cover;
    }

    .store-id-logo-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 26px; /* Add padding for spacing */
        margin-top: 25px;
        margin-bottom: 15px;
    }

    .title {
        font-size: 1.1rem;
    }

    .tenant-logo {
        width: 60px; /* Adjust width as needed */
        height: auto; /* Maintain aspect ratio */
        object-fit: contain; /* Ensure the logo fits within the container */
    }

    .add-vpa-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10vh;
        background-color: #5f5f5f;
        color: white;
        font-weight: 500;
        font-size: 1.2rem;
        padding: 0 28px;

        button,
        button:hover,
        button:active,
        button.btn:active {
            background-color: #ece6f0;
            color: #65558f;
            height: 70%;
            border: none;
            font-size: 14px;
            border-radius: 0.8rem;
            text-transform: uppercase;
            font-weight: 500;
            padding: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .tenant-contact-details {
        display: flex;
        justify-content: center;
        width: 100%;

        .form-control, .form-select{
            width: calc(100vw - 28 * 2px);
            background-color: #e6e0e9;
            border-color: #e6e0e9;;

            border-bottom: 1px solid #49454f;
            margin-top: 16px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: none;
        }

        .enable {
            label::after {
                background-color: #e6e0e9 !important;
                color: #49454f !important;
            }
        }

        .disable {
            .form-control {
                background-color: #f6f6f6 !important;
                border: none;
                border-bottom: 1px solid #a4a3a5 !important;
                color: #a4a3a5;
                pointer-events: none;
            }
            label::after {
                background-color: #f6f6f6 !important;
            }
            .form-control:disabled ~ label,
            .form-control:not(:placeholder-shown) ~ label {
                color: #a4a3a5;
            }
        }
    }
}

.form-control:focus {
    border: none;
    box-shadow: none;
}

.tenant-navbar-header {
    padding: 30px 10px 30px 20px;
    background-color: #f3edf7;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    svg {
        // margin-right: 25px;
        margin-left: 10px;
        cursor: pointer;
        width: 25px;
        height: 25px;
    }

    button,
    button:hover,
    button:active,
    button.btn:active {
        background-color: #e8def8;
        color: #1d192b;
        font-size: 14px;
        padding: 10px 24px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10rem;
        text-transform: uppercase;
        font-weight: 500;
    }

    .subheading {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px; /* Set your preferred width */
    height: 100%;
    background-color: #ece6f0; /* Background color of the sidebar */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Ensure it appears above other elements */
    padding: 10px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    padding: 10px;
    cursor: pointer;
}

.sidebar li:hover {
    background-color: #f0f0f0; /* Change on hover */
}

.vpa-page {
    .title {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .list-card {
        display: flex;
        background-color: #fef7ff;
        margin-bottom: 16px;

        .list-card-img {
            padding: 5%;
            img {
                width: 100px;
                max-height: 200px;
                object-fit: fill;
            }
        }

        .card-body {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 14px;
            padding: 0 5%;
            overflow: scroll;
            .title {
                margin-top: 5%;
                font-size: 14px;
            }
            .item {
                margin-bottom: 16px;
            }
        }

        button,
        button:hover,
        button:active,
        button.btn:active {
            margin: 0;
            align-self: flex-end;

            background-color: #e8def8;
            color: #1d192b;
            font-size: 14px;
            padding: 10px 24px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10rem;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}

.floating-btn {
    position: fixed;
    bottom: 50px; /* Distance from the bottom */
    right: 30px; /* Distance from the right */

    button,
    button:hover,
    button:active,
    button.btn:active {
        background-color: #ece6f0;
        color: #65558f;
        border: none;
        font-size: 14px;
        border-radius: 0.8rem;
        text-transform: uppercase;
        font-weight: 500;
        padding: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }

    svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}

.qr-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .list-card {
        display: flex;
        background-color: #fef7ff;
        margin: 16px 0;
        width: 85%;
        overflow: hidden;
        img {
            height: 100px;
        }
        .list-card-img {
            padding: 5%;
        }

        .card-body {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 16px;
            font-size: 14px;
            padding: 0 5%;
            overflow: scroll;

            .title {
                margin-top: 5%;
            }
            .item {
                margin-bottom: 16px;
            }
        }

        button,
        button:hover,
        button:active,
        button.btn:active {
            margin: 0;
            align-self: flex-end;

            background-color: #e8def8;
            color: #1d192b;
            font-size: 14px;
            padding: 10px 24px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10rem;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .scanner {
        div {
            padding-top: 5vw !important;
            position: relative !important;
            justify-content: center !important;
            align-items: center !important;
            display: flex;
            width: 100vw;
        }
    }

    video {
        position: relative !important;
        width: 80vw !important;
        border: 1px solid #49454f;
        border-radius: 10px;
    }
}
